import Vue from 'vue';
import VueRouter from 'vue-router';
import i18n from '../translations/index'

Vue.use(VueRouter);


import introComponent from '../views/intro';
import overviewComponent from '../views/overview';

import impressumComponent from '../views/general/impressum';
import aboutusComponent from '../views/general/about-us';

import lunarphasesComponent from '../views/works/lunar-phases';
import starmapComponent from '../views/works/star-map';
import sunspotsComponent from '../views/works/sun-spots';
import jupitersmoonsComponent from '../views/works/jupiters-moons';
import astrolabComponent from '../views/works/astrolab';
import uranometriaComponent  from '../views/works/uranometria';
import harmoniaComponent  from '../views/works/harmonia';
import inkunabelComponent  from '../views/works/inkunabel';
import atlasNovusComponent  from '../views/works/atlas-novus';
import halleyCometComponent  from '../views/works/halley-comet';
import moonPassingComponent  from '../views/works/moon-passing';
import coelumStellatumComponent  from '../views/works/coelum-stellatum';
import volvelleComponent  from '../views/works/volvelle';
import saturniumComponent  from '../views/works/saturnium';
import saturnRingsComponent  from '../views/works/saturn-rings';
import lunarClockComponent  from '../views/works/lunar-clock';

import zoomLunarPhasesComponent from '../views/zoom/zoom-lunar-phases';
import zoomStarsMapComponent from '../views/zoom/zoom-stars-map';
import zoomSunSpotsComponent from '../views/zoom/zoom-sun-spots';
import zoomAstrolabComponent from '../views/zoom/zoom-astrolab';
import zoomHarmoniaComponent from '../views/zoom/zoom-harmonia';
import zoomInkunabelComponent from '../views/zoom/zoom-inkunabel';
import zoomAtlasNovusComponent from '../views/zoom/zoom-atlas-novus';
import zoomHalleyCometComponent from '../views/zoom/zoom-halley-comet';
import zoomMoonPassingComponent from '../views/zoom/zoom-moon-passing';
import zoomCoelumStellatumComponent from '../views/zoom/zoom-coelum-stellatum';
import zoomVolvelleComponent from '../views/zoom/zoom-volvelle';
import zoomSaturniumComponent from '../views/zoom/zoom-saturnium';
import zoomSaturnRingsComponent from '../views/zoom/zoom-saturn-rings';
import zoomLunarClockComponent from '../views/zoom/zoom-lunar-clock';



const routes = [
  {
    path: '/:lang',
    component: {
      template: '<router-view></router-view>',
    },
    meta: {
      // title: 'Overview',
      metaTags: [
        {
          name: 'description',
          content: 'desc',
        },
        {
          property: 'og:description',
          content: 'desc',
        },
      ],
    },
    children: [
      { path: '', component: introComponent },
      { path: 'overview', component: overviewComponent },

      { path: 'impressum', component: impressumComponent },
      { path: 'about-us', component: aboutusComponent },

      { path: 'lunar-phases', component: lunarphasesComponent },
      { path: 'star-map', component: starmapComponent },
      { path: 'sun-spots', component: sunspotsComponent },
      { path: 'jupiters-moons', component: jupitersmoonsComponent },
      { path: 'astrolab', component: astrolabComponent },
      { path: 'uranometria', component: uranometriaComponent },
      { path: 'harmonia', component: harmoniaComponent },
      { path: 'inkunabel', component: inkunabelComponent },
      { path: 'atlas-novus', component: atlasNovusComponent },
      { path: 'halley-comet', component: halleyCometComponent },
      { path: 'moon-passing', component: moonPassingComponent },
      { path: 'coelum-stellatum', component: coelumStellatumComponent },
      { path: 'volvelle', component: volvelleComponent },
      { path: 'systema-saturnium', component: saturniumComponent },
      { path: 'saturn-rings', component: saturnRingsComponent },
      { path: 'lunar-clock', component: lunarClockComponent },

      { path: 'zoom-lunar-phases/:id', name:'zoom-lunar-phases',  component: zoomLunarPhasesComponent},
      { path: 'zoom-star-map', name:'zoom-star-map', component: zoomStarsMapComponent },
      { path: 'zoom-sun-spots/:id', name:'zoom-sun-spots', component: zoomSunSpotsComponent },
      { path: 'zoom-astrolab', name:'zoom-astrolab', component: zoomAstrolabComponent },
      { path: 'zoom-harmonia/:id', name:'zoom-harmonia', component: zoomHarmoniaComponent },
      { path: 'zoom-inkunabel/:id', name:'zoom-inkunabel', component: zoomInkunabelComponent },
      { path: 'zoom-atlas-novus/:id/:kind', name:'zoom-atlas-novus',  component: zoomAtlasNovusComponent},
      { path: 'zoom-halley-comet/:id', name:'zoom-halley-comet', component: zoomHalleyCometComponent },
      { path: 'zoom-moon-passing/:id', name:'zoom-moon-passing', component: zoomMoonPassingComponent },
      { path: 'zoom-coelum-stellatum/:id/:type', name:'zoom-coelum-stellatum', component: zoomCoelumStellatumComponent },
      { path: 'zoom-volvelle', name:'zoom-volvelle', component: zoomVolvelleComponent },
      { path: 'zoom-systema-saturnium/:id', name:'zoom-systema-saturnium', component: zoomSaturniumComponent },
      { path: 'zoom-saturn-rings/:id', name:'zoom-saturn-rings', component: zoomSaturnRingsComponent },
      { path: 'zoom-lunar-clock', name:'zoom-lunar-clock', component: zoomLunarClockComponent },

    ]
  },
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
