<script>
  export default {
    name: 'saturnRings',
    data: function () {
      return {
        bookMarkers : [
          {
            id: 0,
            deg: 85,
            imgSaturn: "1655-03-saturn.jpg",
            imgMap: "1655-03-map.jpg",
            legend : "März 1655"
          },
          {
            id: 1,
            deg: 90.5,
            imgSaturn: "1655-11-saturn.jpg",
            imgMap: "1655-11-map.jpg",
            legend : "November 1655"
          },
          {
            id: 2,
            deg: 95,
            imgSaturn: "1656-10-saturn.jpg",
            imgMap: "1656-10-map.jpg",
            legend : "Oktober 1656"
          },
          {
            id: 3,
            deg: 100,
            imgSaturn: "1657-03-saturn.jpg",
            imgMap: "1657-03-map.jpg",
            legend : "März 1657"
          },
          {
            id: 4,
            deg: 105,
            imgSaturn: "1659-02-saturn.jpg",
            imgMap: "1659-02-map.jpg",
            legend : "Februar 1659"
          },
        ],
      }
    }
  };
</script>
